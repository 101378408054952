import { createStore } from "vuex";

export default createStore({
    state: {
        selected_poly: null,
    },
    mutations: {
        setSelectedPoly(state, value) {
            state.selected_poly = value;
        },
    },
    actions: {
        updateSelectedPoly({ commit }, value) {
            commit("setSelectedPoly", value);
        },
    },
    getters: {
        getSelectedPoly: (state) => state.selected_poly,
    },
});
